
import { Component, Vue } from 'vue-property-decorator'
import { getApp } from '@/helpers/feathers'
import LanguagePicker from '@/components/LanguagePicker/LanguagePicker.vue'
import validator from 'validator'

/**
 * Maske für das Senden einer 'Passwort vergessen'-Mail.
 */
@Component({
  components: {
    LanguagePicker
  }
})
export default class ForgotPasswordMask extends Vue {
  /**
   * Gibt an, ob die Eingabe gültig ist.
   */
  public valid = false

  /**
   * Eingegebene E-Mail-Adresse.
   */
  public email = ''

  /**
   * Gibt an, ob eine E-Mail abgeschickt wurde.
   */
  public emailSent = false

  /**
   * Gibt an, ob ein Fehler aufgetreten ist.
   */
  public error = false

  /**
   * Gibt an, ob der Ladevorgang abgeschlossen ist.
   */
  public loading = false

  /**
   * Startet den Prozess zum Senden einer E-Mail mit einem
   * 'Passwort zurücksetzen'-Link.
   *
   * @param event - das Event des Submit Buttons.
   */
  public async sendPasswordResetMail(event: Event): Promise<void> {
    // Form Submit unterbinden.
    if (typeof event !== 'undefined') {
      event.preventDefault()
    }
    this.loading = true
    this.error = false
    this.emailSent = false

    if (this.valid) {
      try {
        await (await getApp())
          .service('password-reset')
          .create({}, { query: { $email: this.email } })
        this.emailSent = true
        this.loading = false
      } catch (err) {
        this.error = true
        this.loading = false
        throw err
      }
    }
  }

  /**
   * Überprüfen ob das Feld eine E-Mail beinhaltet.
   *
   * @returns Regeln für das E-Mail-Feld.
   */
  public get emailRule(): ((v: boolean) => string | true)[] {
    return [
      (v: boolean): true | string =>
        (!!v && validator.isEmail(String(v))) ||
        this.$t('forgotPasswordMask.forgotPassword.error').toString()
    ]
  }
}
