
import { Component, Mixins, Watch } from 'vue-property-decorator'
import Dark from '@/mixins/dark'
import { getApp } from '@/helpers/feathers'
import { getDashboardID } from '@/helpers/dashboard'
import LanguagePicker from '@/components/LanguagePicker/LanguagePicker.vue'
import TabbedCard from '@/components/TabbedCard/TabbedCard.vue'
import { getService } from '../../helpers/feathers'
import jsCookie from 'js-cookie'
import { Connection } from '@/offline/connection.enum'
import { config } from '@/config'
import ExternalLogin from '@/components/ExternalLogin/ExternalLogin.vue'
import PinLogin from '@/components/PinLogin/PinLogin.vue'
import StatementSection from '@/components/StatementSection/StatementSection.vue'
import { PermissionHelper } from '@/helpers/permission-helper'
import { state } from '@/store/language/state'
import { LicenseData } from '../../interfaces/licenseData.schema'
import { RawLocation } from 'vue-router'
import { EnvironmentMixin } from '@/mixins/environment.mixin'
import { MaintenanceData } from './maintenanceData.schema'
import { Getter } from 'vuex-class'
import { UITheme } from '@/store/ui/theme.interface'
import { isUserLoggedIn } from '@/helpers/isUserLoggedIn.helper'

/**
 * Login-Maske mit verschiedenen Login-Strategien, zwischen denen per Tabs
 * gewecheselt werden kann
 */
@Component({
  components: {
    LanguagePicker,
    TabbedCard,
    ExternalLogin,
    PinLogin,
    StatementSection
  }
})
export default class LoginMask extends Mixins(Dark, EnvironmentMixin) {
  /**
   * eplas-Logo im Light Mode.
   */
  public logoLight: string = require('@/assets/logo_white.svg')

  /**
   * eplas-Logo im Dark Mode.
   */
  public logoDark: string = require('@/assets/logo.svg')

  /**
   * Benutzername Eingabefeld
   */
  public username = ''

  /**
   * Passwort Eingabefeld
   */
  public password = ''

  /**
   * Gibt an, ob der Login gültig ist
   */
  public valid = false

  /**
   * Gibt an, ob der Login gerade stattfindet
   */
  public loginInProgress = false

  /**
   * Gibt an, ob beim Login ein Fehler aufgetreten ist
   */
  public loginError = false

  /**
   * Aktive Loginmethoden
   */
  public loginMethods: string[] = []

  /**
   * Boolean, ob die Authentifizierungsstrategien der Tabs bereits geladen
   * wurden
   * Zustand ist 'false', wenn die Authentifizierungsstrategien geladen wurden
   */
  public tabsLoading = true

  /**
   * Die ID des eigenen Dashboards von dem User
   */
  public userDashboardId: number | null = null

  /**
   * zeigt an, ob man die Loginmaske sieht oder nur ein Hinweis, dass kein Login
   * bei Offline möglich ist
   */
  public loginAvail = true

  /**
   * Standardmäßig vorausgewählter Tab der Authentifizierungsmethoden
   */
  public defaultTab = ''

  /**
   * Warten bis man an das korrekte Dashboard weitergeleitet wird.
   * In der Zwischenzeit sieht man einen Ladebildschirm und die Login-
   * Maske wird ausgeblendet.
   */
  private waitingForForward = false

  /**
   * Aktiver Tab der Authentifizierungsmethoden.
   */
  public activeTab = ''

  /**
   * zeigt an, ob die "Backend offline" Fehlermeldung gezeigt wird oder nicht
   */
  public showAlert = false

  /**
   * Die Daten zum Wartungsmodus
   */
  public wartungData: MaintenanceData = {
    enabled: false,
    title: [],
    text: []
  }

  /**
   * Zeigt an, ob die Loginmaske ausgeblendet werden soll. Wird verwendet,
   * um ein "Aufblinken" der Loginmaske zu verhindern,
   * wenn man aus dem 10L-Logout kommt.
   */
  public dontShowLogin = true

  /**
   * Einstellungen vom Theme, im UI Store
   */
  @Getter('ui/theme')
  public uiStoreTheme!: UITheme

  /**
   * Triggert ein Neuladen der Seite
   */
  public reload(): void {
    this.$router.go(0)
  }

  /**
   * Leitet bei bestehender Session automatisch vom Login zum Dashboard um und
   * holt sich die aktiven Loginmethoden
   */
  public async created(): Promise<void> {
    if (this.$store.getters['connectivity/offline']) {
      // offline handling
      this.dontShowLogin = false
      const jwt = jsCookie.get('feathers-jwt')

      if (!jwt) {
        throw new Error('Offline but no JWT stored.')
      }

      const offlineauth = await (await getService('auth', Connection.Offline))
        .get(jwt)
        .catch((): null => null)

      if (offlineauth === null) {
        this.loginAvail = false
        return
      }

      this.$router.push(`audit/offline`)
    } else {
      // online handling
      // Zuerst die Anmeldestrategie abfragen
      try {
        await this.loadMaintenanceValues()

        await this.loadLicenseValues()

        await PermissionHelper.revokePermissionsOnInvalidLicense()

        const result = await (await getApp())
          .service('authentication-strategies')
          .find()
        this.loginMethods = result
        // Für Fremdmitarbeiter-Login folgende Zeile einkommentieren:
        // this.loginMethods.push('external')

        this.defaultTab = this.loginMethods[0]

        this.tabsLoading = false
      } catch (error) {
        localStorage.removeItem('lastUser')
        jsCookie.remove('feathers-jwt')
        if (error.name === 'NotAuthenticated') {
          this.reload()
          return
        }
        this.showAlert = true
      }
      // Erst jetzt prüfen, ob ein User eingeloggt ist.
      // Dann funktioniert es auch mit dem SAML-Login komplett richtig \^o^/
      try {
        if (await isUserLoggedIn()) {
          await this.forwardAfterLogin()
        }
      } catch (error) {
        throw error
      }
    }
    this.dontShowLogin = false
    this.setTabFromRoute()
  }

  /**
   * Loggt den Benutzer ein
   *
   * @param event - das Event des Submit Buttons
   * @param loginMode - Gibt an welche Login-Strategie verwendet werden soll
   */
  public async login(event: Event, loginMode: string): Promise<void> {
    // Form Submit unterbinden.
    if (typeof event !== 'undefined') {
      event.preventDefault()
    }

    if (loginMode === 'local') {
      if (this.username === '' || this.password === '') {
        return
      }

      const app = await getApp()

      this.loginInProgress = true
      this.loginError = false

      try {
        const response = await app.authenticate({
          strategy: 'local',
          payload: {
            username: this.username,
            password: this.password
          }
        })
        this.setLanguage()

        if (
          response.passwordChangeRequired ||
          localStorage.getItem('changePwFirst')
        ) {
          this.$emit('startPasswordRoutine', response.user.id)
          return
        }
        await this.forwardAfterLogin()
      } catch (error) {
        this.loginInProgress = false
        this.loginError = true
      }
    }
  }

  /**
   * Wertet aus, wohin der User nach dem Login weitergeleitet werden soll.
   *
   * @param referrer - Optional, wohin der User weitergeleitet werden soll. Wird
   * `null` angegeben, wird zuerst im SessionStorage unter `loginReferrer` nach
   * einer URL gesucht, anschließend im Get-Pramater `referrer`. Sollten beide
   * leer sein, wird der User zum Dashboard geschickt.
   */
  private async forwardAfterLogin(referrer: string | null = null) {
    if (!this.loginInProgress) {
      this.waitingForForward = true
    }

    if (referrer === null) {
      referrer = sessionStorage.getItem('loginReferrer')
      sessionStorage.removeItem('loginReferrer')
    }

    if (referrer === null) {
      referrer = new URLSearchParams(window.location.search).get('referrer')
    }

    if (referrer !== null) {
      const url = new URL(referrer, window.location.origin)
      window.location.href = window.location.origin + url.pathname + url.search
    } else {
      await this.forwardToDashboard()
    }
  }

  /**
   * Ruft einen helper auf, der prüft ob der User ein neues Dashboard hat.
   * Leitet ihn je nach Ergebnis weiter auf das neue oder alte eplas und das
   * entsprechende Dashboard
   */
  public async forwardToDashboard(): Promise<void> {
    try {
      this.userDashboardId = await getDashboardID()

      if (!this.loginInProgress) {
        this.waitingForForward = true
      }

      const referrer = new URLSearchParams(window.location.search).get(
        'referrer'
      )

      if (referrer) {
        const url = new URL(referrer, window.location.origin)
        window.location.href =
          window.location.origin + url.pathname + url.search
      } else if (this.userDashboardId === null) {
        window.location.href = window.location.origin + '/index.php/0/eplas'
      } else {
        this.$router.push(`/dashboard/${this.userDashboardId}`)
      }
    } catch (error) {
      localStorage.removeItem('lastUser')
      this.showAlert = true
    }
  }

  /**
   * Ruft die Wartungsmodus-Informationen aus dem Backend ab
   */
  public async loadMaintenanceValues(): Promise<void> {
    try {
      const maintananceValues = await (await getApp())
        .service('maintenance')
        .find()

      this.wartungData = maintananceValues
    } catch (error) {
      throw error
    }
  }

  /**
   * Ruft die Lizenz-Informationen aus dem Backend ab und schreibt diese in
   * den Local-Storage
   */
  public async loadLicenseValues(): Promise<void> {
    try {
      const licenseValues: LicenseData = await (await getApp())
        .service('license')
        .find()

      localStorage.setItem('license', licenseValues.valid ? '1' : '0')
      localStorage.setItem(
        'licenseExpiredDate',
        licenseValues.expireDate.toString()
      )
    } catch (error) {
      localStorage.setItem('license', '-1')
      localStorage.setItem('licenseExpiredDate', '-1')
    }
  }

  /**
   * Gibt den für die ausgewählte Sprache passenden Titel für die
   * Wartungsmodus-Meldung zurück
   *
   * @returns Titel für die Wartungsmodus-Meldung
   */
  public get maintenanceTitle(): string {
    let titleCurrent
    let titleFallback
    if (this.wartungData.title) {
      this.wartungData.title.forEach(translation => {
        if (translation.language === this.$i18n.locale) {
          titleCurrent = translation.translation ?? ''
        }
        if (translation.language === this.$i18n.fallbackLocale) {
          titleFallback = translation.translation ?? ''
        }
      })
    }

    return titleCurrent ?? titleFallback ?? ''
  }

  /**
   * Gibt den für die ausgewählte Sprache passenden Text für die
   * Wartungsmodus-Maledung zurück
   *
   * @returns Text für die Wartungsmodus-Meldung
   */
  public get maintenanceText(): string {
    let textCurrent
    let textFallback
    if (this.wartungData.text) {
      this.wartungData.text.forEach(translation => {
        if (translation.language === this.$i18n.locale) {
          textCurrent = translation.translation ?? ''
        }
        if (translation.language === this.$i18n.fallbackLocale) {
          textFallback = translation.translation
        }
      })
    }

    return textCurrent ?? textFallback ?? ''
  }

  /**
   * Setzt die Sprache auf den im Sessionstorage gespeicherten Wert oder den
   * Standardwert
   */
  private setLanguage(): void {
    this.$i18n.locale = sessionStorage.getItem('language') || state.default
  }

  /**
   * Definiert die Regeln für die Eingabe des Benutzernamens
   *
   * @returns Regeln für das Usernamefeld
   */
  public get usernameRules(): ((v: boolean) => string | true)[] {
    return [
      (v: boolean): true | string =>
        !!v || this.$t('loginMask.error.usernameValidation').toString()
    ]
  }

  /**
   * Definiert die Regeln für die Eingabe des Passworts
   *
   * @returns Regeln für das Passwordfeld
   */
  public get passwordRules(): ((v: boolean) => string | true)[] {
    return [
      (v: boolean): true | string =>
        !!v || this.$t('loginMask.error.passwordValidation').toString()
    ]
  }

  /**
   * Gibt das Logo zurück
   *
   * @returns Pfad zum Logo
   */
  public get logo(): string {
    const config = this.dark ? this.uiStoreTheme.dark : this.uiStoreTheme.light

    if (config.loginLogo) {
      const logo = config.loginLogo.replace(/[\\/]/g, '_')
      return `${process.env.BASE_URL || '/'}custom/${logo}`
    }

    return this.dark ? this.logoDark : this.logoLight
  }

  /**
   * Gibt true aus, wenn ein Fehler beim Anmelden mit Benutzername auftritt
   *
   * @returns true: wenn Fehler
   */
  public get usernameError(): boolean {
    return this.loginError
  }

  /**
   * Leitet zur SAML-Anmeldemaske weiter.
   */
  public async openSAMLLogin(): Promise<void> {
    const referrer = new URLSearchParams(window.location.search).get('referrer')
    if (referrer !== null) {
      sessionStorage.setItem('loginReferrer', referrer)
    }

    window.location.assign(`${(await config.client()).api.url}/auth/saml/login`)
  }

  /**
   * Setzt den aktiven Tab auf den in der Route angegebenen Tab, falls der
   * Parameter gegeben ist.
   */
  public setTabFromRoute(): void {
    const { tab } = this.$route.params

    this.defaultTab = tab || this.loginMethods[0]
  }

  /**
   * Ändert den ausgewählten Tab in der Route.
   *
   * @param tab - Der Tab, der in die Route geschrieben werden soll.
   */
  public changeRouteTab(tab: string): void {
    if (!this.tabsLoading) {
      this.$route.params.tab = tab
      this.$router.push(this.$route as RawLocation).catch(() => {})
    }
  }

  /**
   * Aktiviert einen Tab.
   *
   * @param tab - Der zu aktivierende Tab.
   */
  public activateTab(tab: string): void {
    this.activeTab = tab
  }

  /**
   * Prüft, ob alle Felder innerhalb des Formulars valide sind.
   * Falls nicht, wird eine Meldung in der jeweils gewählten Sprache ausgegeben.
   */
  @Watch('$i18n.locale')
  private formValidate(): void {
    const form = this.$refs.form as HTMLFormElement
    form.validate()
  }

  /**
   * Prüft, ob die Wartungsmodus-Meldung ausgeblendet werden kann
   *
   * @returns true, wenn die Meldung ausgeblendet werden darf
   */
  public maintenanceDismissible(): boolean {
    const url = window.location.origin
    return url.includes('/portal.') || this.isDevelopment
  }
}
