
import { Mixins, Component, Watch } from 'vue-property-decorator'
import Dark from '@/mixins/dark'
import { GenericErrorData } from '@/interfaces/GenericErrorData.interface'
import validator from 'validator'
import ErrorableCard from '@/components/ErrorableCard/ErrorableCard.vue'
import { getApp } from '@/helpers/feathers'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import setPasswordContent from '@/components/SetPasswordContent/SetPasswordContent.vue'
import { getPasswordValidationError } from '@/helpers/get-password-rule-error'

/**
 * Verschiedene Seiten der PIN-Login-Maske.
 */
enum Page {
  /**
   * Erste Seite der PIN-Eingabe.
   */
  PIN = 1,
  /**
   * Auf dieser Seite kann der Benutzer seine Daten ändern/vervollständigen.
   */
  Infos = 2,
  /**
   * Auf dieser Seite kann man ein Passwort eingeben.
   */
  Password = 3,
  /**
   * Auf dieser Seite wird der neue Anmeldename angezeigt.
   */
  Summary = 4
}

/**
 * Daten, die man vom Server erhalten hat, die zu er eingegebenen PIN gehören.
 */
export interface PinData {
  /**
   * ID des Mitarbeiterdatensatzes
   */
  id: number

  /**
   * Neues Passwort des Mitarbeiters
   */
  password: string

  /**
   * Vorname des Mitarbeites
   */
  firstName?: string

  /**
   * Nachname des Mitarbeiters
   */
  lastName?: string

  /**
   * Email-Adresse des Mitarbeiters
   */
  email?: string

  /**
   * Telefonnummer des Mitarbeiters
   */
  _Telefon?: string

  /**
   * Mobilnummer des Mitarbeiters
   */
  _mobile?: string

  /**
   * Geburtstag des Mitarbeiters
   */
  birthday?: string

  /**
   * eingegebene PIN
   */
  pin?: string
}

/**
 * PIN Login
 */
@Component({
  components: {
    ErrorableCard,
    DatePicker,
    setPasswordContent
  }
})
export default class PinLogin extends Mixins(Dark) {
  /**
   * Speichert die eingegebene PIN.
   */
  public pin = ''

  /**
   * Die Daten, die der PIN zugehörig sind.
   */
  public employeeInfos: PinData | null = null

  /**
   * Aktive Seite der PIN-Eingabe.
   */
  public page: Page = Page.PIN

  /**
   * Fehlerdaten. Der Fehler wird in der Maske angezeigt, wenn dieses Feld
   * gesetzt ist.
   */
  public error: GenericErrorData | null = null

  /**
   * Gibt an, ob die Fehlermeldung für die Passwortregeln angezeigt wird.
   */
  public showPasswordValidationError = false

  /**
   * Gibt an, ob momentan eine Hintergrundaktivität stattfindet. Dadurch werden
   * bspw. Buttons deaktiviert.
   */
  public loginInProgress = false

  /**
   * Gibt an, ob die Daten der aktuellen Seite valide sind.
   * Kann dazu genutzt werden um z.B. Buttons zu sperren.
   */
  public validData = false

  /**
   * Fehlermeldung bei Passwortvalidation.
   */
  public passwordValidationError: string | null = null

  /**
   * Der neue Username, den der Leiharbeitnehmer nach der Eingabe seiner
   * Daten und eines neuen Passworts angezeigt bekommt.
   */
  public generatedUsername?: string

  /**
   * Überprüft, ob die PIN in der Datenbank vorhanden und gültig ist.
   */
  public async checkPIN(): Promise<void> {
    this.loginInProgress = true
    this.error = null
    if (
      !validator.isLength(this.pin, {
        min: 12,
        max: 12
      })
    ) {
      this.abortWithError()
      return
    }

    let result: PinData[]

    try {
      result = await (await getApp()).service('pin-login').find({
        query: {
          $pin: this.pin
        }
      })
    } catch (ex) {
      this.abortWithError()
      return
    }

    if (result.length !== 1) {
      this.abortWithError()
      return
    }

    ;[this.employeeInfos] = result

    this.nextPage()
  }

  /**
   * Schreibt die geänderten Mitarbeiter-Daten in den Mitarbeiter-Datensatz.
   *
   * @param password - Passwort Daten aus der SetPasswordContent-Komponente
   */
  public async updateData(password: string): Promise<void> {
    this.loginInProgress = true
    this.error = null

    if (!this.employeeInfos || !password) {
      this.abortWithError()
      return
    }

    this.employeeInfos.password = password

    const { id, ...data } = this.employeeInfos

    const params = {
      query: {
        $pin: this.pin
      }
    }

    try {
      this.showPasswordValidationError = false
      const result = await (await getApp())
        .service('pin-login')
        .patch(id, data, params)

      this.generatedUsername = result.username
    } catch (ex) {
      let errorKey = null
      errorKey = await getPasswordValidationError(ex.message)

      if (!errorKey) {
        this.showPasswordValidationError = false
        this.abortWithError()
      }

      this.showPasswordValidationError = true
      this.passwordValidationError = errorKey
        ? 'pinLogin.error.' + errorKey
        : 'pinLogin.error.general'
      return
    }
    this.nextPage()
    this.reset()
  }

  /**
   * Erhöht die Anzahl der aktuellen Seite.
   * Vorherige Fehler werden entfernt.
   */
  public nextPage(): void {
    this.error = null
    this.loginInProgress = false
    this.validData = false
    this.page++
  }

  /**
   * Verringert die Zahl der aktuellen Seite, um so eine Seite zurück zu gehen.
   */
  public prevPage(): void {
    this.page--
  }

  /**
   * Setzt einige Werte zurück, die während des Vorgangs gesetzt wurden.
   */
  private reset(): void {
    this.error = null
    this.loginInProgress = false
    this.validData = false
    this.pin = ''
    this.employeeInfos = null
  }

  /**
   * Öffnet den "Login"-Tab der Authentifizerungsmaske, damit der
   * Leiharbeitnehmer sich zum ersten Mal selbstständig einloggen kann.
   */
  public activateLoginTab(): void {
    this.$emit('activate-tab', 'local')
  }

  /**
   * Trägt bei einem Input des Date-Pickers die Daten in das Objekt ein.
   *
   * @param value - Datum aus dem Date-Picker
   */
  public onDatePickerInput(value: string): void {
    if (this.employeeInfos) {
      this.employeeInfos.birthday = value
    }
  }

  /**
   * Überprüfung ob ein Pflichtfeld Inhalt hat.
   *
   * @returns Regeln für Pflichtfelder
   */
  public get requiredRule(): ((v: boolean | number) => string | true)[] {
    return [
      (v: boolean | number): true | string =>
        !!v || this.$t('pinLogin.rule.required').toString()
    ]
  }

  /**
   * Überprüfen ob das Feld eine E-Mail beinhaltet.
   *
   * @returns Regeln für E-Mail-Felder
   */
  public get emailRule(): ((v: boolean) => string | true)[] {
    return [
      (v: boolean): true | string =>
        !v ||
        validator.isEmail(String(v)) ||
        this.$t('pinLogin.rule.email').toString()
    ]
  }

  /**
   * Setzt einen Fehler und beendet den Hintergrundprozess.
   *
   * @param code - Code-Nummer, die angezeigt werden soll.
   */
  private abortWithError(code?: number): void {
    this.error = {
      code: code || 0x0001
    }

    this.loginInProgress = false
  }

  /**
   * Prüft, ob alle Felder innerhalb des Formulars valide sind.
   * Falls nicht, wird eine Meldung in der jeweils gewählten Sprache ausgegeben.
   */
  @Watch('$i18n.locale')
  private formValidate(): void {
    const form = this.$refs.formPin as HTMLFormElement
    form.validate()
  }
}
