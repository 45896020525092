
import { Component, Vue } from 'vue-property-decorator'
import LoginMask from '@/components/LoginMask/LoginMask.vue'
import SetPasswordMask from '@/components/SetPasswordMask/SetPasswordMask.vue'
import ForgotPasswordMask from '@/components/ForgotPasswordMask/ForgotPasswordMask.vue'
import DefaultBackground from '@/components/DefaultBackground/DefaultBackground.vue'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'

/**
 * Verschiedene Modi der Login Maske mit unterschiedlichen Funktionalitäten
 */
enum MaskMode {
  /**
   * Standardansicht mit verschiedenen Loginstrategien
   */
  Login = 'login',
  /**
   * Benutzer setzt sein Passwort bei erstem Login
   */
  SetPassword = 'setPassword',
  /**
   * Benutzer hat auf 'Passwort vergessen?'-Knopf geklickt
   */
  ForgotPassword = 'forgotPassword'
}

/**
 * Login-View
 */
@Component({
  components: {
    LoginMask,
    SetPasswordMask,
    BaseFrame,
    ForgotPasswordMask,
    DefaultBackground
  }
})
export default class Login extends Vue {
  /**
   * Modus der Login Maske (siehe enum)
   */
  public maskMode: MaskMode = MaskMode.Login

  /**
   * ID des Benutzers, der sich eingeloggt hat
   */
  public userID = 0

  /**
   * Created.
   */
  public created() {
    if (localStorage.getItem('changePwFirst')) {
      this.userID = parseFloat(localStorage.getItem('changePwFirst') || '0')
      this.maskMode = MaskMode.SetPassword
    }
  }

  /**
   * Wird getriggert, wenn ein Benutzer sich einloggt und sein Passwort noch
   * nicht gesetzt hat. Öffnet daraufhin die dazugehörige Maske zum Setzen des
   * Passworts
   *
   * @param userID - ID des Benutzers, der sich eingeloggt hat
   */
  public startPasswordRoutine(userID: number): void {
    this.userID = userID
    this.maskMode = MaskMode.SetPassword

    localStorage.setItem('changePwFirst', userID.toString())
  }

  /**
   * Ändert den Mask Mode auf den mitgegebene Wert
   *
   * @param mode - Der gewünschte Mode auf den gewechselt werden soll
   */
  public switchMaskMode(mode: MaskMode): void {
    this.maskMode = mode
  }
}
